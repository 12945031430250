.content-container {
    margin: 2rem;
  }
  
  .item {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
  }
  
  .bullet {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
  
  .text {
    font-size: 1.2rem;
  }
  
  ul {
    margin-top: 0.5rem;
    margin-left: 2rem;
  }
  
  li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .ff-powered-img {
    visibility: hidden;
    /* transform: translateX(-9999px); */
    margin-bottom: -80px;
  }

